/* .background { */
  /* background-image: url("../assets/ClusterCode Logo Transpanent BG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
  /* opacity: 0.1; */
  /* position: relative;
}

.background::before{
    content: '';
    background-image: url('../assets/ClusterCode Logo Transpanent BG.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.15;
} */

.image {
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  opacity: 1;
}

.image.fade {
  opacity: 0; /* Fade out when changing */
}
